import React from 'react';
import { ShopOutlined, AppstoreOutlined, GlobalOutlined } from '@ant-design/icons';

import manufacture from '../assets/images/wanlandfutures.jpg';
import boxes from '../assets/images/firms.jpg';
import delivery from '../assets/images/fura.jpg';



const FeaturesVertical = () => {
    const features = [
      {
        icon: <ShopOutlined className="text-2xl text-[#0070BB]" />,
        title: 'Официальный диллер ©Wanlanda',
        description: 'Первый официальный сертифицированный дилер завода-производителя фильтрующих элементов ©WANLANDA на территории Сибирского Федерального округа',
        image: manufacture
      },
      {
        icon: <AppstoreOutlined className="text-2xl text-[#0070BB]" />,
        title: 'Широкий ассортимент',
        description: 'Широкий выбор запчастей и комплектующих для различных марок спецтехники: Caterpillar, Atlas Copco, Liebherr, Komatsu и другие',
        image: boxes
      },
      {
        icon: <GlobalOutlined className="text-2xl text-[#0070BB]" />,
        title: 'Логистика',
        description: 'Отлаженные логистические цепочки и партнерство с надежными транспортными компаниями гарантируют своевременную доставку в любую точку России.',
        image: delivery
      }
    ];
  
    return (
      <div className="w-full bg-white">
        {features.map((feature, index) => (
          <div 
            key={index}
            className={`
              flex flex-col md:flex-row items-center 
              ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}
              py-16 px-4 md:px-8 
              ${index !== features.length - 1 ? 'border-b border-white' : ''}
            `}
          >
            {/* Контент слева */}
            <div className="w-full md:w-1/2 px-4 md:px-8 mb-8 md:mb-0">
              <div className="flex flex-col items-center md:items-start text-center md:text-left">
                <div className="flex items-center mb-4">
                  {/* <div className="mr-3 transform hover:scale-110 transition-transform duration-300">
                    {feature.icon}
                  </div> */}
                  <h2 className="text-3xl font-bold text-gray-900">
                    {feature.title}
                  </h2>
                </div>
                <p className="text-xl text-gray-600 leading-relaxed">
                  {feature.description}
                </p>
              </div>
            </div>
  
            {/* Изображение справа */}
            <div className="w-full md:w-1/2 px-4 md:px-8">
              <div className="relative overflow-hidden rounded-lg">
                <img 
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-auto transform hover:scale-105 transition-transform duration-500"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default FeaturesVertical;

// const FeaturesVertical = () => {
//   const features = [
//     {
//       icon: <ShopOutlined className="text-6xl text-[#0070BB]" />,
//       title: 'Официальный диллер ©Wanlanda',
//       description: 'Первый официальный сертифицированный дилер завода-производителя фильтрующих элементов ©WANLANDA на территории Сибирского Федерального округа',
//       image: manufacture
//     },
//     {
//       icon: <AppstoreOutlined className="text-6xl text-[#0070BB]" />,
//       title: 'Широкий ассортимент',
//       description: 'Широкий выбор запчастей и комплектующих для различных марок спецтехники: Caterpillar, Atlas Copco, Liebherr, Komatsu и другие',
//       image: boxes
//     },
//     {
//       icon: <GlobalOutlined className="text-6xl text-[#0070BB]" />,
//       title: 'Логистика',
//       description: 'Отлаженные логистические цепочки и партнерство с надежными транспортными компаниями гарантируют своевременную доставку в любую точку России.',
//       image: delivery
//     }
//   ];

//   return (
//     <div className="w-full bg-white">
//       {features.map((feature, index) => (
//         <div 
//           key={index}
//           className={`
//             flex flex-col md:flex-row items-center 
//             ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}
//             py-16 px-4 md:px-8 
//             ${index !== features.length - 1 ? 'border-b border-white' : ''}
//           `}
//         >
//           {/* Контент слева */}
//           <div className="w-full md:w-1/2 px-4 md:px-8 mb-8 md:mb-0">
//             <div className="flex flex-col items-center md:items-start text-center md:text-left">
//               <div className="mb-6 transform hover:scale-110 transition-transform duration-300">
//                 {feature.icon}
//               </div>
//               <h2 className="text-3xl font-bold mb-4 text-gray-900">
//                 {feature.title}
//               </h2>
//               <p className="text-xl text-gray-600 leading-relaxed">
//                 {feature.description}
//               </p>
//             </div>
//           </div>

//           {/* Изображение справа */}
//           <div className="w-full md:w-1/2 px-4 md:px-8">
//             <div className="relative overflow-hidden rounded-lg shadow-xl">
//               <img 
//                 src={feature.image}
//                 alt={feature.title}
//                 className="w-full h-auto transform hover:scale-105 transition-transform duration-500"
//               />
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default FeaturesVertical;