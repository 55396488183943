import React from 'react';

function ContactPage() {
  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-4xl font-bold mb-8 text-primary">Свяжитесь с нами</h1>
      {/* <p className="mb-6 text-muted-foreground">У вас вопросы, у нас ответы.</p> */}
      
      <form className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-muted-foreground mb-1">Имя</label>
          <input type="text" id="name" name="name" className="w-full p-2 border border-input rounded-md" required />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-muted-foreground mb-1">Email</label>
          <input type="email" id="email" name="email" className="w-full p-2 border border-input rounded-md" required />
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-muted-foreground mb-1">Сообщение</label>
          <textarea id="message" name="message" rows="4" className="w-full p-2 border border-input rounded-md" required></textarea>
        </div>
        <button type="submit" className="bg-primary text-primary-foreground px-6 py-2 rounded-md hover:bg-primary/90 transition-colors border-2">
          Отправить
        </button>
      </form>
      
      {/*<div className="mt-12">
        <h2 className="text-2xl font-semibold mb-4">Наши контакты</h2>
        <p className="mb-2"><strong>Адрес:</strong> г. Новокузнецк, ул. Тольяти, д. 5Б, помещ. 10</p>
        {/* <p className="mb-2"><strong>Телефон:</strong> +7 (111) 222-43-23</p> 
        <p className="mb-2"><strong>Email:</strong> info@anthill.com</p>
      </div> */}
    </div>
  );
}

export default ContactPage;