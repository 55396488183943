import React from 'react';
import { CheckIcon } from "lucide-react"
import backphoto from '../assets/images/kareerjeep.jpg'

export default function Component() {
  const features = [
    {
      title: 'КАЧЕСТВО',
      description: 'Сотрудничество с надежными поставщиками - гарантия высого качества продукции'
    },
    {
      title: 'РАБОТАЕМ ПО ВСЕЙ СТРАНЕ',
      description: 'Оперативная доставка запасных частей по всей России. Наличие отлаженных логистических цепочек позволяет гарантировать минимальные сроки поставки'
    },
    {
      title: 'ПОДДЕРЖКА',
      description: 'Постоянное поддержание необходимых товаров позволяет обеспечивать бесперебойные поставки клиентам'
    },
    {
      title: 'ИНДИВИДУАЛЬНЫЙ ПОДХОД',
      description: 'Обечпечение комфортных условий сотрудничества'
    }
  ];

  return (
    <div className="p-4 md:p-8">
      <div 
        className="rounded-3xl overflow-hidden relative"
        style={{
          backgroundImage: `url(${backphoto})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Затемняющий оверлей */}
        <div className="absolute inset-0 bg-black bg-opacity-75" />

        {/* Контент */}
        <div className="relative z-10 p-8 md:p-16 text-white">
          {/* Main Heading */}
          {/* <h1 className="text-4xl md:text-5xl font-bold mb-2">
             - Ваш надежный партнёр{' '}
            <span className="block mt-2">
              в поставке запчастей и шин для спецтехники
            </span>
          </h1> */}

          {/* Subheading */}
          <h2 className="text-2xl md:text-3xl mt-8 mb-12">
            ПОЧЕМУ МЫ
          </h2>

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="flex gap-4">
                <div className="flex-shrink-0">
                  <div className="w-10 h-10 bg-zinc-800 bg-opacity-50 rounded-lg flex items-center justify-center">
                    <CheckIcon className="w-6 h-6 text-white" />
                  </div>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-zinc-200 leading-relaxed">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}