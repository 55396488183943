// import React, { useState } from 'react';
// import korobs from '../assets/images/korobs.jpg'
// import sklad from '../assets/images/sklad.jpg'
// import trucks from '../assets/images/truck_home_page.jpg'
// import pered from '../assets/images/peredovietech.jpg'

// // Компонент одной плитки
// const Tile = ({ image, title, description }) => {
//   const [isFlipped, setIsFlipped] = useState(false);
//   const [showFullText, setShowFullText] = useState(false);

//   return (
//     <div 
//       className="relative w-full aspect-square group cursor-pointer perspective-1000"
//       onClick={() => {
//         if (isFlipped) setShowFullText(!showFullText);
//       }}
//       onMouseEnter={() => !showFullText && setIsFlipped(true)}
//       onMouseLeave={() => !showFullText && setIsFlipped(false)}
//     >
//       <div className={`relative preserve-3d duration-500 w-full h-full ${isFlipped ? 'rotate-y-180' : ''}`}>
//         {/* Передняя сторона с изображением */}
//         <div className="absolute w-full h-full backface-hidden">
//           <img 
//             src={image} 
//             alt={title}
//             className="w-full h-full object-cover rounded-2xl"
//           />
//         </div>

//         {/* Задняя сторона с текстом */}
//         <div className="absolute w-full h-full backface-hidden rotate-y-180 bg-[#c1ff72] rounded-2xl p-6 overflow-auto">
//           {showFullText ? (
//             <div className="text-black">
//               <h3 className="text-xl font-bold mb-4">{title}</h3>
//               <p className="text-base">{description}</p>
//             </div>
//           ) : (
//             <div className="text-black">
//               <h3 className="text-xl font-bold">{title}</h3>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// // Основной компонент с сеткой плиток
// const Plitki = () => {
//   const tiles = [
//     {
//       image: pered,
//       title: 'Официальный дилер WANLANDA',
//       description: 'Компания АНТХИЛЛ является первым официальным сертифицированным дилером завода-производителя фильтрующих элементов под брендом WANLANDA на территории Сибирского Федерального округа.'
//     },
//     {
//       image: korobs,
//       title: 'Широкий ассортимент',
//       description: 'Фильтры подходят для широкой техники - от легковых автомобилей до карьерной спецтехники. По качеству сравнимы с оригиналами мировых производителей, таких как: Shantui, Shacman, Shaanxi, Howo, КАМАЗ.'
//     },
//     {
//       image: trucks,
//       title: 'Оперативная доставка',
//       description: 'АНТХИЛЛ обеспечивает оперативную доставку запасных частей по всему Сибирскому региону и России. Наличие отлаженных логистических цепочек позволяет гарантировать минимальные сроки поставки при конкурентных ценах.'
//     },
//     {
//       image: sklad,
//       title: 'Индивидуальный подход',
//       description: 'Индивидуальный подход к каждому клиенту помогает формировать оптимальные складские запасы с учетом сезонности и специфики эксплуатации техники.'
//     }
//   ];

//   return (
//     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
//         {tiles.map((tile, index) => (
//           <Tile key={index} {...tile} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Plitki;

import React, { useState } from 'react';
import manufacture from '../assets/images/wanlanda_office.jpg';
import boxes from '../assets/images/nasos.jpg';
import delivery from '../assets/images/truckdelivery_obrez.jpg';
import stock from '../assets/images/korobs.jpg';
import truck from '../assets/images/truck_home_page.jpg';
import warehouse from '../assets/images/rukopojatie.jpg';

const Tile = ({ image, title, description }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const spanishblue = '#0070BB';
  const salat = '#c1ff72';

  return (
    <div 
      className="relative w-full aspect-square cursor-pointer perspective-1000"
      onClick={() => {
        if (isFlipped) {
          setShowFullText(!showFullText);
        }
      }}
      onMouseEnter={() => !showFullText && setIsFlipped(true)}
      onMouseLeave={() => !showFullText && setIsFlipped(false)}
    >
      <div 
        className={`relative preserve-3d duration-500 w-full h-full ${
          isFlipped ? 'rotate-y-180' : ''
        }`}
      >
        {/* Передняя сторона */}
        <div className="absolute w-full h-full backface-hidden">
          <img 
            src={image} 
            alt={title}
            className="w-full h-full object-cover rounded-2xl"
          />
        </div>

        {/* Задняя сторона */}
        <div className="absolute w-full h-full backface-hidden rotate-y-180 bg-[#0070BB] rounded-2xl overflow-hidden">
          <div 
            className={`flex flex-col h-full justify-center transition-all duration-500 ${
              showFullText ? 'px-6 pt-8' : 'p-6'
            }`}
          >
            <h3 
              className={`text-white font-bold text-center mb-4 transition-all duration-500 ${
                showFullText ? 'text-xl' : 'text-3xl'
              }`}
            >
              {title}
            </h3>
            {/* <div 
              className={`text-white transition-all duration-500 overflow-auto ${
                showFullText ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-8 hidden'
              }`}
            >
              {description}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const Plitki = () => {
  const tiles = [
    {
      image: manufacture,
      title: 'Официальный диллер ©Wanlanda',
      description: 'Первый официальный сертифицированный дилер завода-производителя фильтрующих элементов ©WANLANDA на территории Сибирского Федерального округа'
    },
    {
      image: boxes,
      title: 'Широкий ассортимент',
      description: 'Широкий выбор запчастей и комплектующих для различных марок спецтехники: Caterpillar, Atlas Copco, Liebherr, Komatsu и другие'
    },
    {
      image: delivery,
      title: 'Логистика',
      description: 'Отлаженные логистические цепочки и партнерство с надежными транспортными компаниями гарантируют своевременную доставку в любую точку России.'
    },
    {
      image: stock,
      title: 'Складские запасы',
      description: 'Наличие собственного склада и постоянное поддержание необходимого количества товаров позволяет нам обеспечивать бесперебойные поставки нашим клиентам.'
    },
    {
      image: truck,
      title: 'Работаем по всей стране',
      description: 'Отлаженные логистические цепочки и партнерство с надежными транспортными компаниями гарантируют своевременную доставку в любую точку России.'
    },
    {
      image: warehouse,
      title: 'Индивидуальный подход',
      description: 'Индивидуальный подход к каждому клиенту помогает формировать оптимальные складские запасы с учетом сезонности и специфики эксплуатации техники.'
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tiles.map((tile, index) => (
          <Tile key={index} {...tile} />
        ))}
      </div>
    </div>
  );
};

export default Plitki;