// import React from 'react';

// function Footer() {
//   return (
//     <footer className="bg-white text-primary-foreground mt-auto">
//       <div className="container mx-auto px-4 py-6">
        
//         <div className='mb-4'>
//         <p><strong>ООО «АНТХИЛЛ»</strong></p>
//         <p><strong>ИНН:</strong> 8942384012938340 </p>
//         <p><strong>Контактный номер:</strong> Имя +7 213 432-43-23 </p>
        
//         <p className="mb-2"><strong>Адрес:</strong> г. Новокузнецк, ул. Тольяти, д. 5Б, помещ. 10</p>
//         {/* <p className="mb-2"><strong>Телефон:</strong> +7 (111) 222-43-23</p> */}
//         <p className="mb-2"><strong>Email:</strong> info@anthill.com</p>
//         </div>
//         <div className="flex flex-col md:flex-row justify-between items-center">
//           <p>&copy; 2023 Anthill. Все права защищены.</p>
//           {/* <div className="mt-4 md:mt-0">
//             <a href="#" className="hover:text-primary-foreground/80 transition-colors mr-4">Политика конфиденциальности</a>
//             <a href="#" className="hover:text-primary-foreground/80 transition-colors">Условия использования</a>
//           </div> */}
          
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Footer;


// import React from 'react';
// import { MapPin, Phone, Mail, Clock } from 'lucide-react';

// function Footer() {
//   return (
//     <footer className="bg-gray-50 text-gray-600">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         {/* Основная информация */}
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 py-12">
//           {/* О компании */}
//           <div>
//             <h3 className="text-lg font-semibold text-gray-900 mb-4">О компании</h3>
//             <p className="text-sm mb-4">ООО «АНТХИЛЛ»</p>
//             <p className="text-sm mb-2">ИНН: 8942384012938340</p>
//             <div className="flex items-center gap-2 text-sm">
//               <Clock className="h-4 w-4" />
//               <span>Пн-Пт: 9:00-18:00</span>
//             </div>
//           </div>

//           {/* Контакты */}
//           <div>
//             <h3 className="text-lg font-semibold text-gray-900 mb-4">Контакты</h3>
//             <div className="space-y-3">
//               <div className="flex items-center gap-2">
//                 <Phone className="h-4 w-4" />
//                 <a href="tel:+72134324323" className="text-sm hover:text-gray-900 transition-colors">
//                   +7 213 432-43-23
//                 </a>
//               </div>
//               <div className="flex items-center gap-2">
//                 <Mail className="h-4 w-4" />
//                 <a href="mailto:info@anthill.com" className="text-sm hover:text-gray-900 transition-colors">
//                   info@anthill.com
//                 </a>
//               </div>
//               <div className="flex items-start gap-2">
//                 <MapPin className="h-4 w-4 mt-1" />
//                 <p className="text-sm">
//                   г. Новокузнецк, <br />
//                   ул. Тольяти, д. 5Б, <br />
//                   помещ. 10
//                 </p>
//               </div>
//             </div>
//           </div>

//           {/* Услуги */}
//           <div>
//             <h3 className="text-lg font-semibold text-gray-900 mb-4">Услуги</h3>
//             <ul className="space-y-2">
//               <li>
//                 <a href="#" className="text-sm hover:text-gray-900 transition-colors">Поставка фильтров</a>
//               </li>
//               <li>
//                 <a href="#" className="text-sm hover:text-gray-900 transition-colors">Подбор аналогов</a>
//               </li>
//               <li>
//                 <a href="#" className="text-sm hover:text-gray-900 transition-colors">Оптовые поставки</a>
//               </li>
//               <li>
//                 <a href="#" className="text-sm hover:text-gray-900 transition-colors">Доставка</a>
//               </li>
//             </ul>
//           </div>

//           {/* Документы */}
//           <div>
//             <h3 className="text-lg font-semibold text-gray-900 mb-4">Документы</h3>
//             <ul className="space-y-2">
//               <li>
//                 <a href="#" className="text-sm hover:text-gray-900 transition-colors">
//                   Политика конфиденциальности
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="text-sm hover:text-gray-900 transition-colors">
//                   Условия использования
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="text-sm hover:text-gray-900 transition-colors">
//                   Сертификаты
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>

//         {/* Нижняя часть футера */}
//         <div className="border-t border-gray-200 py-6">
//           <div className="flex flex-col md:flex-row justify-between items-center gap-4">
//             <p className="text-sm">
//               © {new Date().getFullYear()} АНТХИЛЛ. Все права защищены.
//             </p>
//             <div className="flex gap-6">
//               <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
//                 <span className="sr-only">VK</span>
//                 <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
//                   <path d="M15.684 0H8.316C1.592 0 0 1.592 0 8.316v7.368C0 22.408 1.592 24 8.316 24h7.368C22.408 24 24 22.408 24 15.684V8.316C24 1.592 22.408 0 15.684 0zm3.692 16.773h-1.744c-.66 0-.862-.523-2.049-1.709-1.033-1.005-1.49-1.135-1.745-1.135-.356 0-.458.102-.458.597v1.575c0 .424-.135.672-1.236.672-1.83 0-3.865-1.112-5.293-3.178-2.15-3.047-2.742-5.322-2.742-5.794 0-.254.102-.491.596-.491h1.744c.44 0 .61.203.78.677.864 2.487 2.303 4.675 2.896 4.675.22 0 .322-.102.322-.66V9.793c-.068-1.186-.695-1.287-.695-1.71 0-.203.17-.407.44-.407h2.742c.373 0 .508.204.508.643v3.473c0 .372.17.508.271.508.22 0 .407-.136.813-.542 1.254-1.406 2.15-3.574 2.15-3.574.119-.254.305-.491.728-.491h1.744c.525 0 .644.27.525.643-.22 1.015-2.353 4.031-2.353 4.031-.186.305-.254.44 0 .78.186.254.796.779 1.203 1.253.745.847 1.32 1.558 1.473 2.048.17.474-.085.716-.576.716z"/>
//                 </svg>
//               </a>
//               <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
//                 <span className="sr-only">Telegram</span>
//                 <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
//                   <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.223-.548.223l.188-2.623 4.823-4.351c.212-.19-.043-.295-.323-.105l-5.96 3.738-2.525-.785c-.55-.176-.564-.576.114-.852l9.84-3.8c.47-.176.878.107.89.583z"/>
//                 </svg>
//               </a>
//               <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
//                 <span className="sr-only">WhatsApp</span>
//                 <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
//                   <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z"/>
//                 </svg>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Footer;



import React from 'react';
import { MapPin, Phone, Mail, Clock } from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-gray-50 text-gray-600">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Основная информация */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-12">
          {/* О компании */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">О компании</h3>
            <p className="text-sm mb-4">ООО «АНТХИЛЛ»</p>
            <p className="text-sm mb-2">ИНН: 4217208795</p>
            <div className="flex items-center gap-2 text-sm">
              <Clock className="h-4 w-4" />
              <span>Пн-Пт: 9:00-18:00</span>
            </div>
          </div>

          {/* Контакты */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Контакты</h3>
            <div className="space-y-3">
              <div className="flex items-center gap-2">
                <Phone className="h-4 w-4" />
                <a href="tel:+79059068465" className="text-sm hover:text-gray-900 transition-colors">
                  +7-905-906-84-65
                </a>
              </div>
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4" />
                <a href="mailto:anthillsib@gmail.com" className="text-sm hover:text-gray-900 transition-colors">
                anthillsib@gmail.com
                </a>
              </div>
              <div className="flex items-start gap-2">
                <MapPin className="h-4 w-4 mt-1" />
                <p className="text-sm">
                654066, Кемеровская обл. – Кузбасс, <br />
                г. Новокузнецк, ул. Тольятти д. 5Б, <br />
                  помещ. 10
                </p>
              </div>
            </div>
          </div>

          {/* Услуги */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Услуги</h3>
            <ul className="space-y-2">
              <li>
                <a className="text-sm hover:text-gray-900 transition-colors">Поставка фильтров</a>
              </li>
              <li>
                <a className="text-sm hover:text-gray-900 transition-colors">Подбор аналогов</a>
              </li>
              <li>
                <a className="text-sm hover:text-gray-900 transition-colors">Оптовые поставки</a>
              </li>
              <li>
                <a className="text-sm hover:text-gray-900 transition-colors">Доставка</a>
              </li>
            </ul>
          </div>
        </div>

        {/* Нижняя часть футера */}
        <div className="border-t border-gray-200 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-sm">
              © {new Date().getFullYear()} АНТХИЛЛ. Все права защищены.
            </p>
            {/* <div className="flex gap-6">
              <a href="#" aria-label="VK" className="text-gray-400 hover:text-gray-600 transition-colors">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M15.684 0H8.316C1.592 0 0 1.592 0 8.316v7.368C0 22.408 1.592 24 8.316 24h7.368C22.408 24 24 22.408 24 15.684V8.316C24 1.592 22.408 0 15.684 0zm3.692 16.773h-1.744c-.66 0-.862-.523-2.049-1.709-1.033-1.005-1.49-1.135-1.745-1.135-.356 0-.458.102-.458.597v1.575c0 .424-.135.672-1.236.672-1.83 0-3.865-1.112-5.293-3.178-2.15-3.047-2.742-5.322-2.742-5.794 0-.254.102-.491.596-.491h1.744c.44 0 .61.203.78.677.864 2.487 2.303 4.675 2.896 4.675.22 0 .322-.102.322-.66V9.793c-.068-1.186-.695-1.287-.695-1.71 0-.203.17-.407.44-.407h2.742c.373 0 .508.204.508.643v3.473c0 .372.17.508.271.508.22 0 .407-.136.813-.542 1.254-1.406 2.15-3.574 2.15-3.574.119-.254.305-.491.728-.491h1.744c.525 0 .644.27.525.643-.22 1.015-2.353 4.031-2.353 4.031-.186.305-.254.44 0 .78.186.254.796.779 1.203 1.253.745.847 1.32 1.558 1.473 2.048.17.474-.085.716-.576.716z"/>
                </svg>
              </a>
              <a href="#" aria-label="Telegram" className="text-gray-400 hover:text-gray-600 transition-colors">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.223-.548.223l.188-2.623 4.823-4.351c.212-.19-.043-.295-.323-.105l-5.96 3.738-2.525-.785c-.55-.176-.564-.576.114-.852l9.84-3.8c.47-.176.878.107.89.583z"/>
                </svg>
              </a>
              <a href="#" aria-label="WhatsApp" className="text-gray-400 hover:text-gray-600 transition-colors">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z"/>
                </svg>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;