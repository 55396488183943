import React from 'react';
import { Link } from 'react-router-dom';

function AboutPage() {
  return (
    <div className="space-y-8">
      <section className="text-center">
        <h1 className="text-4xl font-bold mb-4 text-primary">О нас</h1>
        <p className="text-xl mb-6 text-muted-foreground">Ваш надежный источник аналогов фильтров и комплектующих для замены</p>
        <Link to="/products" className="bg-primary text-primary-foreground px-6 py-2 rounded-md hover:bg-primary/90 transition-colors">
          Просмотреть каталог
        </Link>
      </section>
      
      <section className="grid md:grid-cols-2 gap-8">
        <div className="bg-card text-card-foreground p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Наша миссия</h2>
          <p>Обеспечить вас качественными деталями по доступным ценам, поддерживая ваше оборудование в отличном состоянии.</p>
        </div>
        <div className="bg-card text-card-foreground p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Почему выбирают нас</h2>
          <ul className="list-disc list-inside">
            <li>Широкий ассортимент продукции</li>
            <li>Гарантия качества</li>
            <li>Конкурентные цены</li>
            <li>Быстрая доставка</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default AboutPage;