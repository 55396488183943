// import {Image} from 'antd'
// import { useState } from 'react'
// import truckhome from '../assets/images/truck_home_page.jpg'
// // import SimpleCarousel from '../components/Carusel.js'
// import MarqueeBrands from '../components/MarqueeBrands.js'
// import Textandphoto from '../components/Textandphoto.js'
// import Photoandtext from '../components/Photoandtext.js'


// export default function HomePage() {
//   return (
//     <div className="w-full " style={{color:'#ffffff', backgroundColor:'#ffffff'}}>
//       <div className="bg-white pt-8 pb-16 px-4 sm:px-6 lg:px-8">
//         <div className="max-w-7xl mx-auto">
//           <div className="relative overflow-hidden rounded-3xl">
//             <Image
//               src={truckhome}
//               width={1200}
//               height={700}
//               alt="Truck on highway"
//               className="w-full h-auto object-cover"
//             />
//             <div className="absolute inset-0 flex flex-col justify-center p-6">
//               <div className="max-w-3xl">
//                 {/* Контейнер для заголовка с белым фоном */}
//                 <div className="bg-white/95 p-6 rounded-lg shadow-lg mb-4 backdrop-blur-sm">
//                   <h1 className="text-gray-900 text-4xl lg:text-5xl font-bold leading-tight">
//                     ООО «АНТХИЛЛ» молодая динамично развивающаяся компания.
//                   </h1>
//                 </div>
                
//                 {/* Контейнер для текста с белым фоном */}
//                 <div className="bg-white/95 p-6 rounded-lg shadow-lg backdrop-blur-sm">
//                   <p className="text-gray-800 text-lg text-2xl leading-relaxed">
//                     Мы обеспечиваем надежность и своевременную поставку качественных запчастей, 
//                     способствуя качественной работе техники наших клиентов и развитию их бизнеса.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div class="flex flex-row text-black w-full">
//         <div class="basis-1/4">01</div>
//         <div class="basis-1/4">02</div>
//         <div class="basis-1/4">03</div>
//       </div>      
      
//       <div className='mb-3'>
//         <MarqueeBrands />
//       </div>
      
//       <div className='mb-14 '>
//         <Textandphoto />
//       </div>
      
//       <div className='mb-5'>
//         <Photoandtext />
//       </div>
//     </div>
//   )
// }

import { Image } from 'antd';
import truckhome from '../assets/images/mine1.jpg';
import MarqueeBrands from '../components/MarqueeBrands.js';
import Textandphoto from '../components/Textandphoto.js';
import Photoandtext from '../components/Photoandtext.js';
import Plitki from '../components/Plitki.js';
import Aboutall from '../components/Aboutall.js';
import Features from '../components/Features.js';
import Mapelement  from '../components/Mapelement.js';
import Brandslogo from '../components/Brandslogo.js';

export default function HomePage() {
  return (
    <div className="w-full bg-white">
      {/* Hero section */}
      <section className="pt-8 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative overflow-hidden rounded-3xl">
            <img
              src={truckhome}
              alt="Truck on highway"
              className="w-full h-auto object-cover"
              style={{ height: '500px' }}
            />
            <div className="absolute inset-0 flex flex-col justify-center p-6">
              <div className="max-w-3xl">
                <div className="bg-white/85 p-6 rounded-lg shadow-lg mb-4 backdrop-blur-sm">
                  <h1 className="text-gray-900 text-4xl lg:text-5xl font-bold leading-tight">
                    «АНТХИЛЛ» — надежный поставщик комплектующих и запасных частей 
                  </h1>
                </div>
                {/* <div className="bg-white/95 p-6 rounded-lg shadow-lg backdrop-blur-sm">
                  <p className="text-gray-800 text-lg lg:text-2xl leading-relaxed">
                    Мы обеспечиваем надежность и своевременную поставку качественных запчастей,
                    способствуя качественной работе техники наших клиентов и развитию их бизнеса.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Numbers section
      <section className="mb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex text-black w-full">
            <div className="flex-1 text-center">01</div>
            <div className="flex-1 text-center">02</div>
            <div className="flex-1 text-center">03</div>
          </div>
        </div>
      </section> */}

      

      {/* Brands section */}
      <section className="mb-12">
        <div className="max-w-7xl mx-auto">
          {/* <MarqueeBrands /> */}
          <Brandslogo />
        </div>
      </section>

      <Plitki />

      <Features />

      <Mapelement />

      {/* <Aboutall /> */}

      {/* Content sections
      <section className="mb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="space-y-12">
            <Textandphoto />
            <Photoandtext />
          </div>
        </div>
      </section> */}

    </div>
  );
}