// import React, { useState } from 'react';
// import truck from '../assets/images/truckdelivery_obrez.jpg'
// import caterpillar from '../assets/images/company/caterpilar.png'
// import komatsu from '../assets/images/company/komatsu.png'
// import cummins from '../assets/images/company/cummins.png'
// import hitachi from '../assets/images/company/hitachi.png'
// import liebherr from '../assets/images/company/liebherr.png'
// import shantui from '../assets/images/company/shantui.png'
// import shacman from '../assets/images/company/shacman.png'
// import shaanxi from '../assets/images/company/shaanxi.png'
// import howo from '../assets/images/company/howo.png'
// import volvo from '../assets/images/company/volvo.png'
// import john_deree from '../assets/images/company/john_deere.png'
// import atlas_copco from '../assets/images/company/atlas_copso.png'
// import epiroc from '../assets/images/company/epiroc.png'

// const Brandslogo = () => {
//   const tiles = [
//     {
//       image: caterpillar,
//       title: 'Caterpillar'
//     },
//     {
//       image: komatsu,
//       title: 'Komatsu'
//     },
//     {
//       image: cummins,
//       title: 'Cummins'
//     },
//     {
//       image: hitachi,
//       title: 'Hitachi'
//     },
//     {
//       image: liebherr,
//       title: 'Liebherr'
//     },
//     {
//       image: shantui,
//       title: 'Shantui'
//     },
//     {
//       image: shacman,
//       title: 'Shacman'
//     },
//     {
//       image: howo,
//       title: 'Howo'
//     },
//     {
//       image: volvo,
//       title: 'Volvo'
//     },
//     {
//       image: shaanxi,
//       title: 'Shaanxi'
//     },
//     {
//       image: john_deree,
//       title: 'John Deere'
//     },
//     {
//       image: atlas_copco,
//       title: 'Atlas Copco'
//     },
//     {
//       image: epiroc,
//       title: 'Epiroc'
//     },
//   ];

//   return (

//   );
// };

'use client'
import React, { useRef, useEffect, useState } from 'react'

// Импорты изображений
import caterpillar from '../assets/images/company/caterpilar.png'
import komatsu from '../assets/images/company/komatsu.png'
import cummins from '../assets/images/company/cummins.png'
import hitachi from '../assets/images/company/hitachi.png'
import liebherr from '../assets/images/company/liebherr.png'
import shantui from '../assets/images/company/shantui.png'
import shacman from '../assets/images/company/shacman.png'
import shaanxi from '../assets/images/company/shaanxi.png'
import howo from '../assets/images/company/howo.png'
import volvo from '../assets/images/company/volvo.png'
import john_deree from '../assets/images/company/john_deere.png'
import atlas_copco from '../assets/images/company/atlas_copso.png'
import epiroc from '../assets/images/company/epiroc.png'

const tiles = [
  { image: caterpillar, title: 'Caterpillar' },
  { image: komatsu, title: 'Komatsu' },
  { image: cummins, title: 'Cummins' },
  { image: hitachi, title: 'Hitachi' },
  { image: liebherr, title: 'Liebherr' },
  { image: shantui, title: 'Shantui' },
  { image: shacman, title: 'Shacman' },
  { image: howo, title: 'Howo' },
  { image: volvo, title: 'Volvo' },
  { image: shaanxi, title: 'Shaanxi' },
  { image: john_deree, title: 'John Deere' },
  { image: atlas_copco, title: 'Atlas Copco' },
  { image: epiroc, title: 'Epiroc' }
]
export default function Brandslogo() {
    const [isPaused, setIsPaused] = useState(false)
    const duplicatedTiles = [...tiles, ...tiles]
  
    return (
      <div className="scroller relative m-auto overflow-hidden bg-white [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]">
        <div
          className="flex w-max items-center"
          style={{ 
            animation: 'scroll 60s linear infinite',
            animationPlayState: isPaused ? 'paused' : 'running'
          }}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          {duplicatedTiles.map((tile, index) => (
            <div
              key={`brand-${index}`}
              className="flex w-[200px] max-w-full items-center justify-center px-6 py-4 mx-2"
            >
              <img
                src={tile.image}
                alt={tile.title}
                className="h-auto max-h-[100px] w-auto max-w-[150px] object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    )
  }