import React from 'react';

function ProductCard({ name, description, price }) {
  return (
    <div className="bg-card text-card-foreground p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-2">{name}</h3>
      <p className="text-muted-foreground mb-4">{description}</p>
      <p className="text-lg font-bold text-primary">{price} руб.</p>
      <button className="mt-4 bg-primary text-primary-foreground px-4 py-2 rounded-md hover:bg-primary/90 transition-colors">
        Добавить в корзину
      </button>
    </div>
  );
}

function ProductsPage() {
  const products = [
    { name: "Фильтр такой то такой то", description: "Фильтр бензиновый", price: 1500 },
    { name: "Комплект такой такой то", description: "Набор комплектующих", price: 2300 },
    { name: "Фильтр уже другой", description: "Универсальный фильтр тойота", price: 1800 },
    { name: "Комплект другой", description: "Профессиональный набор", price: 5000 },
  ];

  return (
    <div>
      <h1 className="text-4xl font-bold mb-8 text-primary">Наши продукты</h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product, index) => (
          <ProductCard key={index} {...product} />
        ))}
      </div>
    </div>
  );
}

export default ProductsPage;